// utils
import { buildJsonLd } from 'src/services/helpers';
// types
import type { FC } from 'react';
import type { MetaData } from 'src/types/metaData.types';

const Seo: FC<MetaData> = ({ title, href, description, schema }) => {
  return (
    <>
      <title>{title}</title>
      <meta property='og:type' content='website' />
      <meta property='og:url' content={href} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content='https://seedium.io/images/logo.png' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@seedium' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content='https://seedium.io/images/logo.png' />
      <meta name='description' content={description} />
      {schema && (
        <script type='application/ld+json'>
          {JSON.stringify(buildJsonLd(schema, href))}
        </script>
      )}
    </>
  );
};

export default Seo;
