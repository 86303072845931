// components
import Seo from 'src/components/Seo';
// hooks
import useProofOfConceptMetaData from 'src/cms/data/proofOfConcept/useProofOfConceptMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const proofOfConceptMetaData = useProofOfConceptMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={proofOfConceptMetaData?.title}
      description={proofOfConceptMetaData?.description}
      schema={proofOfConceptMetaData?.schema}
    />
  );
};

export { default } from './ProofOfConcept';
